import React, { FunctionComponent, useState } from "react";
import { Typography, TextField, Grid, Button, Box, Theme, useTheme } from "@material-ui/core";
import styled from "@emotion/styled";
import addToMailchimp from 'gatsby-plugin-mailchimp';

interface SubscribeProps {
    tagline: string;
    cta: string
}

const PaddedButton = styled(Button)`
margin-left:24px;
`;

const PaddedGridTop = styled(Grid)`
padding-top: 16px;
`;

const FieldBox = styled(Box)((props: {theme: Theme}) =>`
    margin:0 0 0 auto;
    ${props.theme.breakpoints.down("xs")}{
         margin: 0 auto; 
    }
`);

const ResponsiveGrid = styled(Grid)((props: {theme: Theme}) =>`
    ${props.theme.breakpoints.down("xs")}{
         display: flex; justify-content: center; 
    }
`);

const Subscribe: FunctionComponent<SubscribeProps> = (props) => {
    const theme = useTheme<Theme>();

    const [submission, setSubmission] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [success, setSuccess] = useState(false);

    const validateAndSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        if (!firstName) {
            setFirstNameError("Your First Name Is Required");
            return;
        } else {
            setFirstNameError("");
        }

        if (!lastName) {
            setLastNameError("Your Last Name Is Required");
            return;
        } else {
            setLastNameError("");
        }

        if (!email) {
            setEmailError("Your Email Is Required");
            return;
        } else if (!email.match(/^[\+_a-z0-9-'&=]+(\.[\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i)) {
            setEmailError("Email Address Is Not Valid");
            return;
        } else {
            setEmailError("");
        }

        const result = await addToMailchimp(email, {
            FNAME: firstName,
            LNAME: lastName,
            EMAIL: email,
        }, undefined);

        if(result.msg.match(/is already subscribed/)) {
            setSuccess(true);
        } else {
            setSuccess(result.result === "success");
            setSubmission(result.msg);
        }
    };

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom>{props.tagline}</Typography>
            <PaddedGridTop container justify="center" alignItems="center">
                <Grid item xs={12} sm={7}>
                    <FieldBox theme={theme}
                        maxWidth={350} px={2} pb={3}>
                        <TextField fullWidth id="firstName" required autoComplete="given-name" error={firstNameError != ""} label={firstNameError} placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </FieldBox>
                    <FieldBox theme={theme}
                        maxWidth={350} px={2} pb={3}>
                        <TextField fullWidth id="lastName" required autoComplete="family-name" error={firstNameError != ""} label={lastNameError} placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </FieldBox>
                    <FieldBox theme={theme} 
                        maxWidth={350} px={2} pb={3}>
                        <TextField fullWidth id="email" required type="email" autoComplete="email" error={emailError != ""} label={emailError} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </FieldBox>
                </Grid>
                <ResponsiveGrid item xs={12} sm={5} theme={theme}>
                    {!success && <PaddedButton disabled={submission != ""} variant="contained" color="primary" onClick={validateAndSubmit}>{props.cta}</PaddedButton>}
                    {success && <Box px={4}><Typography variant="body1">Thanks for subscribing!</Typography></Box>}
                </ResponsiveGrid>
            </PaddedGridTop>
        </>
    );
}

export default Subscribe;